/* This opacity calc makes the banner fade out inversely to Ionic's collapsible headers.
 * See https://ionicframework.com/docs/api/title#collapsible-large-titles.
 */
.header-banner {
  display: none;
  opacity: calc(1 - var(--opacity-scale));
  transition: all 0.2s ease-in-out;
}

.header-collapse-condense-inactive .header-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
