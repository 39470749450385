/* Reset. */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

ion-tab-bar {
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

/* HACK: this makes the Image config preview fit all its contents. */
.config-Image img {
  height: 90px;
}

/* Make Swiper.js dots the right colors. */
.swiper-pagination-bullet {
  background: var(--ion-color-medium);
}
.swiper-pagination-bullet-active {
  background: var(--ion-color-primary);
}

ion-note {
  color: var(--ion-color-medium);
}

ion-list.custom-inset {
  -webkit-margin-start: 16px;
  margin-inline-start: 16px;
  -webkit-margin-end: 16px;
  margin-inline-end: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
}

.history-swiper .swiper-slide-active {
  display: flex;
  flex-direction: column;
}

.swiper-custom-pagination {
  display: flex;
  justify-content: center;
  margin: 4px 0px !important;
  bottom: initial;
  top: initial;
  left: initial;
  width: initial;
}

/* Ensure the div and table take full width of their container for proper stacking */
.history-swiper .swiper-slide-active > div,
.history-swiper .swiper-slide-active > table {
    width: 100%;
}

/* Fix weird inconsistent text sizes in deck search results list. */
* {
  -webkit-text-size-adjust: 100%;
}

.helpContent img {
  max-width: 100%;
}

.browser-not-supported-container {
  height: 100vh;
  height: -webkit-fill-available;
}

.platform-link {
  color: #222428;
}

.platform-link:hover {
  color: #25a3fc;
}

/* Need to set this to override the default background color for a card preview button. */
button.reviewCard {
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
}

button.reviewCard .audio-container {
  padding: 0;
}

.reviewCard .__match__ {
  border: 2px solid red;
  border-radius: 4px;
}

.reviewCard div,
.reviewCard span,
.reviewCard img,
.reviewCard ruby {
  /* Allow selection of card content during review */
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.reviewCard img {
  max-width: 100%;
  max-height: 70vh;
}

.grid-cell img {
  max-height: 48px !important;
}

.reviewCard audio {
  max-width: 100%;
}

.reviewCard p {
  margin: 0;
  padding: 0;
}

.gen-table-holder {
  width: 100%;
  overflow-x: auto;
  padding: 8px;
  -webkit-overflow-scrolling: touch;
}

.gen-table-holder textarea {
  background-color: var(--ion-background-color);
}

.gen-table-holder>table {
  width: 100%;
  border-collapse: collapse;
}

td.content-col {
  min-width: 120px;
}

.gen-table-holder thead {
  font-weight: bold;
}

.gen-table-holder>table td {
  padding-left: 8px;
  padding-top: 8px;
  vertical-align: middle;
}

.gen-table-holder textarea {
  width: 100%;
}

ion-select.gen-history::part(icon) {
  width: 32px;
  height: 32px;
  opacity: 0.8;
  margin-right: 8px;
}

ion-select.gen-history {
  margin-left: 0 !important;
}

ion-select.gen-history::part(label) {
  display: none;
}

button.select-interface-option.genPopup { }

button.select-interface-option.genPopup:nth-child(odd) {
    background-color: var(--ion-color-light-tint);
}

.support-popup .popover-content {
  width: auto;
  overflow: hidden;
}

.support-popup .sc-ion-select-popover {
  padding-right: 5px;
}

.no-margin-accordion::part(native) {
  padding-left: 0;
}

.no-margin-accordion .ion-accordion-toggle-icon {
  opacity: .2;
  margin-right: 0;
  font-size: large;
}

.select-option-popup {
  --width: 30%;
  --min-width: 250px;
}

.dangerous-confirm {
  color: red;
  font-weight: bold;
}

.pt-8 {
  padding-top: 8px;
}

a {
  color: var(--ion-color-primary);
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

input,
textarea {
  /* Allow selection of input fields */
  -webkit-user-select: initial;
  -ms-user-select: text;
  user-select: initial;
}

.richEditor {
  /* Allow text selection within rich editor. */
  -webkit-user-select: initial;
  -ms-user-select: text;
  user-select: initial;
}

.richEditor * {
  -webkit-user-select: initial;
  -ms-user-select: text;
  user-select: initial;
}

/* HACK: make list items look nicer. */
.richEditor ul,
.richEditor ol {
  padding-left: 0;
}

.richEditor ul,
.richEditor ol li {
  list-style-position: inside;
}

button.reviewCard {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
}

/* Prevent selecting text on clickable card previews. */
button.reviewCard div {
  user-select: none;
}

button:active.reviewCard:not(:disabled) {
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
}

body.dark button.reviewCard {
  box-shadow: 1px 1px 3px 1px #919195;
}

body.dark button:active.reviewCard:not(:disabled) {
  box-shadow: 1px 2px 3px -1px #919195;
}

/* Helper to center IonToast */
.text-center {
  text-align: center;
}

/* HACK: in Safari, when the container is narrow enough, only the play button shows,
 *       and slammed to the far left. This will center that play button.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries. */
.audio-containment-context {
  container-type: inline-size;
}

.audio-container {
  margin: 0 auto;
  padding: 0 8px;
}

@container (width < 240px) {
  .audio-container {
    display: inline-block;
  }
}

/* Dark mode styles for audio player */
.audio-container.dark-mode audio::-webkit-media-controls-panel {
  background-color: #262626;
  /* Dark background */
}

.audio-container.dark-mode audio::-webkit-media-controls-enclosure,
.audio-container.light-mode audio::-webkit-media-controls-enclosure {
  background-color: transparent;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
  filter: alpha(opacity=80);
  opacity: 0.8;
  margin: 8px;
  overflow: hidden;
}

.audio-container.dark-mode audio::-webkit-media-controls-panel {
  background-color: #333;
}

.audio-container.light-mode audio::-webkit-media-controls-panel {
  background-color: #F5F5F5;
}

.audio-container.light-mode audio::-webkit-media-controls-enclosure {
  border: 1px solid #DDD;
}

.audio-container.dark-mode audio::-webkit-media-controls-current-time-display,
.audio-container.dark-mode audio::-webkit-media-controls-time-remaining-display {
  color: #E5E5E5;
  text-shadow: initial;
}

html,
body {
  height: 100%;
}



.pb-8 {
  padding-bottom: 8px;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
    /* Adjust to control height of the bounce */
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

@keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

body {
  font-family: system-ui;
  --ion-font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}

/* Cloze support */
span.clozehide span.cloze {
  color: transparent !important;
  border-bottom: 2px dotted blue;
}

span.clozeshow span.cloze {
  border-bottom: 2px solid blue;
}

.alert-button.danger {
  color: red;
}

.alert-invalid .alert-wrapper .potentially-disabled-alert-button {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.alert-invalid .alert-wrapper .potentially-invalid-alert-input {
  color: var(--ion-color-danger) !important;
}

body.dark .aa-alert-modal .alert-wrapper .alert-input {
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
}

/* Popover Interface: set color for the popover using Item's CSS variables */
.select-interface-option {
  --navy: #001f3f;
  --blue: #0074d9;
  --aqua: #7fdbff;
  --teal: #39cccc;
  --olive: #3d9970;
  --green: #2ecc40;
  --lime: #01ff70;
  --yellow: #ffdc00;
  --orange: #ff851b;
  --red: #ff4136;
  --maroon: #85144b;
  --fuchsia: #f012be;
  --purple: #b10dc9;
  --black: #111111;
  --gray: #aaaaaa;
  --silver: #dddddd;
}

.select-interface-option.option-navy {
  --color: var(--navy);
  --color-hover: var(--navy);
}

.select-interface-option.option-blue {
  --color: var(--blue);
  --color-hover: var(--blue);
}

.select-interface-option.option-aqua {
  --color: var(--aqua);
  --color-hover: var(--aqua);
}

.select-interface-option.option-teal {
  --color: var(--teal);
  --color-hover: var(--teal);
}

.select-interface-option.option-olive {
  --color: var(--olive);
  --color-hover: var(--olive);
}

.select-interface-option.option-green {
  --color: var(--green);
  --color-hover: var(--green);
}

.select-interface-option.option-lime {
  --color: var(--lime);
  --color-hover: var(--lime);
}

.select-interface-option.option-yellow {
  --color: var(--yellow);
  --color-hover: var(--yellow);
}

.select-interface-option.option-orange {
  --color: var(--orange);
  --color-hover: var(--orange);
}

.select-interface-option.option-red {
  --color: var(--red);
  --color-hover: var(--red);
}

.select-interface-option.option-maroon {
  --color: var(--maroon);
  --color-hover: var(--maroon);
}

.select-interface-option.option-fuchsia {
  --color: var(--fuchsia);
  --color-hover: var(--fuchsia);
}

.select-interface-option.option-purple {
  --color: var(--purple);
  --color-hover: var(--purple);
}

.select-interface-option.option-black {
  --color: var(--black);
  --color-hover: var(--black);
}

.select-interface-option.option-gray {
  --color: var(--gray);
  --color-hover: var(--gray);
}

.select-interface-option.option-silver {
  --color: var(--silver);
  --color-hover: var(--silver);
}

.select-interface-option.option-10px {
  font-size: 10px;
}

.select-interface-option.option-13px {
  font-size: 13px;
}

.select-interface-option.option-16px {
  font-size: 16px;
}

.select-interface-option.option-18px {
  font-size: 18px;
}

.select-interface-option.option-24px {
  font-size: 24px;
}

.select-interface-option.option-32px {
  font-size: 32px;
}

.select-interface-option.option-48px {
  font-size: 48px;
}

ion-modal {
  --backdrop-opacity: 0.6 !important;
}

body.dark ion-modal {
  --backdrop-opacity: 0.8 !important;
}

/* Ionic dark mode colors follow. From https://ionicframework.com/docs/theming/dark-mode#ionic-dark-theme */
/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark ion-item {
  --background-activated: #c58bcc;
}

:root {
  --ion-color-primary: #25a3fc;
  --ion-color-primary-rgb: 37, 163, 252;
  --ion-color-primary-shade: #218fde;
  --ion-color-primary-tint: #3bacfc;

  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-color-primary: #bf7ec6;
  --ion-color-primary-rgb: 191, 126, 198;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #a86fae;
  --ion-color-primary-tint: #c58bcc;

  --ion-color-secondary: #8681b5;
  --ion-color-secondary-rgb: 134, 129, 181;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #76729f;
  --ion-color-secondary-tint: #928ebc;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-item-background: #1e1e1e;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;

}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-backdrop-color: #777777;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-50);
  --ion-toolbar-background: var(--ion-color-step-100);
  --ion-toolbar-border-color: var(--ion-color-step-150);
  --ion-item-background: var(--ion-color-step-50);
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}


.glowing-icon svg {
  animation: glow 1500ms ease-in-out infinite alternate;
}
.glowing-icon:hover svg {
  animation: none
}

/* @keyframes for the glowing animation */
@keyframes glow {
  0% {
    filter: drop-shadow(0 0 10px var(--ion-color-primary));
    stroke: var(--ion-color-medium);
    fill: var(--ion-color-medium);
  }
  50% {
    filter: drop-shadow(0 0 5px var(--ion-color-primary));
    stroke: var(--ion-color-primary);
    fill: var(--ion-color-primary);
  }
  100% {
    filter: drop-shadow(0 0 10px var(--ion-color-primary));
    stroke: var(--ion-color-medium);
    fill: var(--ion-color-medium);
  }
}
